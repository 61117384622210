import React from 'react';
import {StatesButton, StatesButtonStates, ThreeDotsLoader} from 'wix-ui-tpa';
import classNames from 'classnames';
import {classes} from './AddToCartButton.st.css';
import * as productPageButtonsContainerStyles from '../ProductPageButtonsContainer.scss';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import {AddToCartState} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/AddToCartService/constants';
import {AddToCartActionStatus} from '../../../constants';

export interface AddToCartButtonProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  productInStock: boolean;
  handleAddToCart: any;
}

@withGlobalProps
@withTranslations('globals.texts')
export class AddToCartButton extends React.Component<AddToCartButtonProps> {
  public AddToCartButtonRef = React.createRef<StatesButton>();
  private a11yAnnouncer: Announcer;

  public componentDidMount(): void {
    this.a11yAnnouncer = new Announcer('product-page-announcer');
  }

  public componentWillUnmount(): void {
    this.a11yAnnouncer.cleanup();
  }

  public componentDidUpdate(prevProps: Readonly<AddToCartButtonProps>): void {
    const {shouldFocusAddToCartButton, addedToCartStatus, userInputs} = this.props.globals;
    if (
      shouldFocusAddToCartButton !== false &&
      shouldFocusAddToCartButton !== prevProps.globals.shouldFocusAddToCartButton
    ) {
      this.AddToCartButtonRef.current.focus();
    }

    if (
      addedToCartStatus !== prevProps.globals.addedToCartStatus &&
      addedToCartStatus === AddToCartActionStatus.SUCCESSFUL
    ) {
      if (userInputs.quantity[0] > 1) {
        this.a11yAnnouncer.announce(
          this.props.t('SR_ANNOUNCE_ADDED_TO_CART_PLURAL', {
            productsNumber: this.props.globals.userInputs.quantity[0],
          })
        );
      } else {
        this.a11yAnnouncer.announce(
          this.props.t('SR_ANNOUNCE_ADDED_TO_CART_SINGULAR', {productName: this.props.globals.product.name})
        );
      }
    }
  }

  private getAddToCartState() {
    const {
      t,
      globals: {experiments, addedToCartStatus},
    } = this.props;
    const {addToCartState} = this.props.globals;
    let disabled = false;
    let caption = t('ADD_TO_CART_BUTTON');

    if (addToCartState === AddToCartState.PRE_ORDER) {
      caption = t('productPage.preOrder.button');
    }

    if (addToCartState === AddToCartState.DISABLED) {
      caption = t('ADD_TO_CART_BUTTON_PRICE_ZERO');
      disabled = true;
    }

    if (addToCartState === AddToCartState.OUT_OF_STOCK) {
      caption = t('PRODUCT_OUT_OF_STOCK_BUTTON');
      disabled = true;
    }

    if (experiments.addToCartBtnLoaderState && addedToCartStatus === AddToCartActionStatus.IN_PROGRESS) {
      disabled = true;
    }

    return {disabled, caption};
  }

  private getButtonState(): StatesButtonStates {
    const {addedToCartStatus, shouldShowAddToCartSuccessAnimation, experiments} = this.props.globals;
    if (shouldShowAddToCartSuccessAnimation && addedToCartStatus === AddToCartActionStatus.SUCCESSFUL) {
      return StatesButtonStates.SUCCESS;
    } else if (experiments.addToCartBtnLoaderState) {
      return addedToCartStatus === AddToCartActionStatus.IN_PROGRESS
        ? StatesButtonStates.IN_PROGRESS
        : StatesButtonStates.IDLE;
    } else {
      return StatesButtonStates.IDLE;
    }
  }

  public render(): JSX.Element {
    const {isMobile, resetAddedToCartStatus, shouldShowWishlistButton} = this.props.globals;
    const {caption, disabled} = this.getAddToCartState();
    const shouldShowWishlist = shouldShowWishlistButton && !isMobile;
    const buttonClasses = classNames({
      [classes.addToCartButton]: true,
      [productPageButtonsContainerStyles.primaryButton]: shouldShowWishlist,
    });
    const buttonState = this.getButtonState();

    return (
      <StatesButton
        state={buttonState}
        ref={this.AddToCartButtonRef}
        idleContent={caption}
        inProgressContent={
          <div className={classes.threeDotsButtonWrapper}>
            <ThreeDotsLoader className={classes.threeDotsButton} data-hook={'add-to-cart-loading-indicator'} />
          </div>
        }
        onNotificationEnd={() => resetAddedToCartStatus()}
        onClick={this.props.handleAddToCart}
        disabled={disabled}
        className={buttonClasses}
        fullWidth={!shouldShowWishlist}
        data-hook={'add-to-cart'}
      />
    );
  }
}
